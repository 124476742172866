import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getRequest } from "../Helpers/RequestApi";
import BookingComp from "./BookingComp";

class NavAndTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedData: {},
      completedData: {},
      cancelledData: {},
    };
  }

  loadBookings = (type) => {
    let user = JSON.parse(localStorage.getItem("user"));
    getRequest("bookings?type=" + type + "&api_key=" + user.api_key)
      .then((res) => {
        if (type == "assigned") {
          this.setState({ assignedData: res.data });
        } else if (type == "completed") {
          this.setState({ completedData: res.data });
        } else {
          this.setState({ cancelledData: res.data });
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.msg, {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
  };

  componentDidMount = () => {
    this.loadBookings("assigned");
    this.loadBookings("completed");
    this.loadBookings("cancelled");
  };
  render() {
    return (
      <Tabs className="jobs-tabs-wrapper">
        <TabList>
          <Tab>Assigned</Tab>
          <Tab>Completed</Tab>
          <Tab>Cancelled</Tab>
        </TabList>
        <TabPanel>
          {Object.entries(this.state.assignedData).map((v, i) => {
            return <BookingComp data={v[1]} />;
          })}
        </TabPanel>
        <TabPanel>
          {Object.entries(this.state.completedData).map((v, i) => {
            return <BookingComp data={v[1]} />;
          })}
        </TabPanel>
        <TabPanel>
          {Object.entries(this.state.cancelledData).map((v, i) => {
            return <BookingComp data={v[1]} />;
          })}
        </TabPanel>
      </Tabs>
    );
  }
}
export default NavAndTabs;
