import React from 'react'
import MainDashBoard from './MainDashBoard';
import EarningCompTable from '../Components/Earnings/EarningCompTable';

const Earnings = props => {
    return (
        <>
        

           <MainDashBoard>
            <EarningCompTable   
            TotalEarnings="$2353.01"
            From="04 JAN 2022"
            To="04 MAR 2022"/>
           </MainDashBoard>
     
       </>
    )
}

export default Earnings
