import { Link } from "react-router-dom";
import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import IntlTelInput from "react-intl-tel-input";
import { Form } from "react-bootstrap";
import { Button } from "bootstrap";

const ViewPageContent = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen1, setIsOpen1] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  return (
    <>
      <div className="container mt-120">
        <div className="row">
          <div className="col-md-6">
            <div className="custom-card ">
              <div className="row">
                  <div className="col-md-6">
                      <p className="badge-success">
                      acct_1IrzgRB2lRlcST6X
                      </p>
                  </div>
                  <div className="col-md-6 text-right">
                      <p className="badge-info">
                      Standard
                      </p>
                  </div>
                  <div className="col-md-6">
                      <p className="mt-3">
                      Country Code<br/> 
                      <strong>USA</strong>
                      </p>
                  </div>
                  <div className="col-md-6 text-right">
                  <p className="mt-3">
                  Currency<br/> 
                      <strong>USD</strong>
                      </p>
                  </div>
                  <div className="col-md-12">
                  <p className="mt-3">
                  Bank Name<br/> 
                      <strong>ABC Bank Ltd</strong>
                      </p>
                  </div>
                  <div className="col-md-12">
                  <p className="mt-3">
                  Account Holder<br/> 
                      <strong>John Doe</strong>
                      </p>
                  </div>
                  <div className="col-md-12">
                  <p className="mt-3">
                  Account Number<br/> 
                      <strong>411XXXXXXX32</strong>
                      </p>
                  </div>
                  <div className="col-md-12">
                  <p className="mt-3">
                  BSB Number (Routing Number)<br/> 
                      <strong>1234</strong>
                      </p>
                  </div>
              </div>
          </div>
              <div className="mt-4">
                <Link to=" " onClick={showModal} className="btn-main btn-block">
                  Update Bank Details
                </Link>
              </div>
            </div>
          <div className="col-md-6">
            <div className="custom-card ">
              <div className="row">
                  <div className="col-md-12">
                  <p className="mt-3">
                  Street Address<br/> 
                      <strong>386 Bird Street</strong>
                      </p>
                  </div>
                  <div className="col-md-12">
                  <p className="mt-3">
                  City<br/> 
                      <strong>Cliff</strong>
                      </p>
                  </div>
                  <div className="col-md-12">
                  <p className="mt-3">
                  State<br/> 
                      <strong>New Mexico</strong>
                      </p>
                  </div>
                  <div className="col-md-12">
                  <p className="mt-3">
                  Post Code<br/> 
                      <strong>88028</strong>
                      </p>
                  </div>
              </div>
          </div>
              <div className="mt-4">
                <Link to=" " onClick={showModal1}  className="btn-main btn-block">
                 Update Address
                </Link>
              </div>
            </div>
        </div>
      </div>
      <Modal show={isOpen} onHide={hideModal}>
      <ModalHeader>
     <i className="fa fa-close close-modal" onClick={hideModal}></i>
    </ModalHeader>
       
        <Modal.Body>
          <Form>
             
            <Form.Label>Bank Name</Form.Label>
            <Form.Control className="mb-3"  type="text" placeholder="Eg. ABC Bank" />
            <Form.Label>Account Holder</Form.Label>
            <Form.Control className="mb-3"  type="text" placeholder="Eg. John Doe" />
            <Form.Label>Account Number</Form.Label>
            <Form.Control className="mb-3"  type="text" placeholder="Eg. 411XXXXX33" />
            <Form.Label>BSB Number (Routing Number)</Form.Label>
            <Form.Control className="mb-3"  type="text" placeholder="Eg. 321465" />
           <button className="btn btn-main btn-block mt-3">Submit</button>
          </Form>
        </Modal.Body> 
      </Modal>
      <Modal show={isOpen1} onHide={hideModal1}>
      <ModalHeader>
     <i className="fa fa-close close-modal" onClick={hideModal1}></i>
    </ModalHeader>
       
        <Modal.Body>
          <Form>
            <Form.Label>Street Address</Form.Label>
            <Form.Control className="mb-3" type="text" />
            <Form.Label>City</Form.Label>
            <Form.Control className="mb-3" type="text" />
            <Form.Label>State</Form.Label>
            <Form.Control className="mb-3" type="text" />
            <Form.Label>Post Code</Form.Label>
            <Form.Control className="mb-3" type="text" />
            <button className="btn btn-main btn-block">Submit</button>
            
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewPageContent;
