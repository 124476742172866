import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import { ToastContainer } from "react-toastify";

const BookingComp = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <ToastContainer />
      <div className="custom-card booking-list-design" onClick={showModal}>
        <div className="row">
          <div className="col-md-3">
            <div className="date-time-wrapper">
              <span className="date-wrap">{props.data.pickup_date}</span>
              {/* <div>
                <span className="month-year-wrap">
                  Mar
                  <br /> 2022
                </span>
              </div> */}
            </div>

            <span className="time-wrap">({props.data.pickup_time})</span>
          </div>
          <div className="col-md-6 route-info">
            <p className="mb-2">
              <strong className="badge badge-secondary">#BK.ID {props.data.booking_ref_id}</strong>
              &nbsp;
              <strong class="badge badge-secondary">
                {props.data.fleet.name}{" "}
              </strong>
            </p>
            <h5>
              <i className="fa fa-map-marker-alt"></i> {props.data.start}
            </h5>

            <h5>
              <i className="fa fa-map-pin"></i> {props.data.end}
            </h5>
          </div>
          <div className="col-md-3 fare-wrapper">
            <span className="badge badge-warning"> {props.data.booking_status}</span>
            <p>$ {props.data.final_fare}</p>
          </div>
        </div>
      </div>
      <Modal size="xl" show={isOpen} onHide={hideModal}>
        <ModalHeader>
          <i className="fa fa-close close-modal" onClick={hideModal}></i>
        </ModalHeader>

        <Modal.Body>
          <div className="row no-gutter">
            <div className="col-md-12">
              <div className="custom-card booking-list-design d-flex justify-content-between align-items-center">
                <h5 className="w-50">
                  <span className="badge badge-dim mr-2">#BK.ID {props.data.booking_ref_id}</span>
                </h5>
              </div>
            </div>
            <div className="col-md-7">
              <div className="booking-list-design">
                <div className="custom-card booking-list-design">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="date-time-wrapper">
                        <span className="date-wrap">{props.data.pickup_date}</span>
                      </div>
                      <span className="time-wrap">({props.data.pickup_time})</span>
                    </div>
                    <div className="col-md-6 route-info">
                      <h5>
                        <i className="fa fa-map-marker-alt"></i> {props.data.start}
                      </h5>

                      <h5>
                        <i className="fa fa-map-pin"></i> {props.data.end}
                      </h5>
                      {/* <h5>
                        <i className="fa fa-clock"></i>{" "}
                        <strong>00:31:40</strong> <i className="fa fa-road"></i>{" "}
                        <strong>19.014 kms</strong>
                      </h5> */}
                    </div>
                    <div className="col-md-3 fare-wrapper">
                      <p>$ {props.data.final_fare}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" booking-list-design ">
                <div className="custom-card booking-list-design">
                  <div className="row">
                    <div className="col-md-6 route-info">
                      <span className="badge badge-dim">
                        Journey Information
                      </span>
                      <h5 className="mt-4">
                        Flight No.<strong>{props.data.service_type == "from_airport" ? props.data.flight_no : "-"}{" "}</strong>
                      </h5>
                      <h5 className="mt-2">
                        Airline Name<strong>{props.data.service_type == "from_airport" ? props.data.flight_name : "-"}{" "}</strong>
                      </h5>
                      <h5>
                      <i className="fa fa-user"></i>{" "}
                        <strong>{props.data.passengers}</strong> &nbsp;{" "}
                        <i className="fa fa-suitcase"></i>{" "}
                        <strong>{props.data.luggage}</strong>{" "}
                        <i className="fa fa-child"></i>{" "}
                        <strong>{props.data.baby_seat}</strong>
                      </h5>
                    </div>

                    <div className="col-md-6 route-info">
                      <span className="badge badge-dim">Special Instruction</span>
                      <p className="mt-4"> {props.data.special_instruction}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" booking-list-design ">
                <div className="custom-card booking-list-design">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        className=" vehicle-img"
                        src={props.data.fleet.image_path}
                        alt="img"
                      />
                    </div>
                    <div className="col-md-6 route-info">
                      {/* <strong class="badge badge-dim">
                        Economy class Executive Sedan (XYZ123){" "}
                      </strong> */}
                      <h5 className="mt-2">
                        <strong>{props.data.fleet.name}</strong>
                      </h5>
                      <h5>
                        <i className="fa fa-user"></i>{" "}
                        <strong>{props.data.fleet.passengers}</strong> &nbsp;{" "}
                        <i className="fa fa-suitcase"></i>{" "}
                        <strong>{props.data.fleet.luggage}</strong> &nbsp;{" "}
                        <i className="fa fa-briefcase"></i>{" "}
                        <strong>{props.data.fleet.hand_luggage}</strong>
                      </h5>
                    </div>
                    <div className="col-md-3  fare-wrapper text-center">
                      {/* <p className="mt-1"> REG321</p> */}
                    </div>
                  </div>
                </div>
              </div>
              {props.data.passenger ? (
                <div className=" booking-list-design ">
                  <div className="custom-card booking-list-design">
                    <div className="row">
                      <div className="col-md-3 text-center">
                        <img
                          className="mr-3 profile-img"
                          src={props.data.passenger.image_path}
                          alt="img"
                        />
                      </div>
                      <div className="col-md-5 route-info">
                        <h5 className="mt-2">
                          Name{" "}
                          <strong>{props.data.passenger_name} </strong>{" "}
                          &nbsp;
                        </h5>
                        <h5>
                          Email{" "}
                          <strong>{props.data.passenger_email?props.data.passenger_email+', ':''} {props.data.passenger_email_additional}</strong>
                        </h5>
                        <h5>
                          Cell No.{" "}
                          <strong>
                            {props.data.passenger_cell_no?props.data.passenger_cell_no+', ':''} {props.data.passenger_cell_no_additional}
                          </strong>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-5">
              <div className="custom-card booking-list-design">
                <strong className=" badge badge-success">{props.data.booking_status}</strong> {' '}
                <strong className=" badge badge-danger">{props.data.is_cancelled?'Cancelled':''}</strong> {' '}
                <strong className=" badge badge-warning">{props.data.is_paid?'Paid':'Unpaid'}</strong>
              </div>
              <div className="custom-card booking-list-design">
                <div className="map-canvas">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d52982.89691128352!2d151.10511082787025!3d-33.904303807288066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x6b12b0f11b3383db%3A0xafdf355d5a4b6577!2sSydney%20Airport%20(SYD)%2C%20Sydney%20NSW%202020%2C%20Australia!3m2!1d-33.9500344!2d151.1819124!4m5!1s0x6b12bad379914ad5%3A0x5017d681632b290!2sConcord%20NSW%2C%20Australia!3m2!1d-33.8559625!2d151.10331309999998!5e0!3m2!1sen!2snp!4v1644923331539!5m2!1sen!2snp"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookingComp;
