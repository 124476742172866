import React from 'react'
import { Link } from 'react-router-dom'

const Logo = () => {
    return (
        <>
        <Link to="/dashboard">
            <figure className='logo'>
                <img src="../images/logo.png" alt=""></img>
            </figure>
            </Link>
        </>
    )
}

export default Logo
