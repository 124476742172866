import React, { useState } from "react";
import InputField from "../InputField";
import Backbtn from "../Backbtn";
import BtnCustom from "../BtnCustom";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { postRequest } from "../../Helpers/RequestApi";
import { ToastContainer, toast } from "react-toastify";

const UserLoginForm = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate  = useNavigate ()

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    postRequest("login", { email: email, password: password })
      .then((res) => {
        setIsLoading(false);
        localStorage.setItem('user', JSON.stringify(res.data));
        navigate ("/dashboard");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      });
  }

  return (
    <>
    <ToastContainer/>
      <div className="page-alignment">
        <div className="form-wrapper custom-card">
          <div className="verify-content">
            <h1>Login</h1>
            <p>Provide following information to Login.</p>
          </div>
          <Form onSubmit={onSubmit}>
          <Form.Control className="mb-3" type="text" name="email" placeholder={"Email/Phone"} required onChange={(e) => setEmail(e.target.value)} />
          <Form.Control className="mb-3" type="password" name="password" placeholder={"Password"} required onChange={(e) => setPassword(e.target.value)} />
          <button className="btn btn-main btn-block" disabled={isLoading}>Login</button>
          </Form>
          <div className="didnotget-code">
            <Link to="/forget-password">Forget Password?</Link>
          </div>
          <p className="text-center mt-3 mb-3">OR</p>
          <Link to="/getphone" className="btn-outline">
            Register
          </Link>
        </div>
      </div>
    </>
  );
};

export default UserLoginForm;
