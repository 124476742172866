import React from "react";
import MainDashBoard from "./MainDashBoard";
import StatementCompTable from "../Components/StatementComp/StatementCompTable";

const Statements = (props) => {
  return (
    <>
      <MainDashBoard>
        <StatementCompTable />
      </MainDashBoard>
    </>
  );
};

export default Statements;
