import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getRequest } from "../../Helpers/RequestApi";
import BtnCustom from "../BtnCustom";

class LandingComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      missingInfo: [
        {
          title: "ACCOUNT NOT VERIFIED",
          info: "Please Click the button below to Verify your Account.",
          icon: "images/no-ac-verify.jpg",
          buttonName: "Verify Account",
          link: "",
        },
        {
          title: "NO BANK DETAIL YET",
          info: "Please Click the button below to add Bank Detail",
          icon: "images/no-bank-ac.jpg",
          buttonName: "Add Bank Info",
          link: "",
        },
        {
          title: "LICENSE DOC. IS EXPIRED",
          info: "Please Click the button below to update your expired document.",
          icon: "images/no-driver-doc.jpg",
          buttonName: "Update Doc",
          link: "",
        },
        {
          title: "FLEET DOCS NOT FOUND",
          info: "Please Click the button below add missing Fleet Document.",
          icon: "images/no-fleetdoc.jpg",
          buttonName: "Add Document",
          link: "",
        },
      ],
      dashBoardData: [
        {
          heading: "Statement",
          number: "0",
          icon: "fa-regular fa-calendar-minus",
        },
        {
          heading: "Completed",
          number: "0",
          icon: "fa-regular fa-calendar-check",
        },
        {
          heading: "Assigned",
          number: "0",
          icon: "fa-regular fa-calendar ",
        },
        {
          heading: "Cancelled",
          number: "0",
          icon: "fa-regular fa-calendar-xmark",
        },
      ],
    };
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  componentDidMount = () => {
    getRequest("dashboard?api_key=" + this.user.api_key)
      .then((res) => {
        let dashBoardData = [
          {
            heading: "Statement",
            number: res.data.count.total_statements,
            icon: "fa-regular fa-calendar-minus",
          },
          {
            heading: "Completed",
            number: res.data.count.total_completed_job,
            icon: "fa-regular fa-calendar-check",
          },
          {
            heading: "Assigned",
            number: res.data.count.total_assigned_job,
            icon: "fa-regular fa-calendar ",
          },
          {
            heading: "Cancelled",
            number: res.data.count.total_cancelled_job,
            icon: "fa-regular fa-calendar-xmark",
          },
        
        ];
        this.setState({ dashBoardData: dashBoardData });
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  render() {
    <ToastContainer />;
    return (
      <div className="container">
        <div className=" dashboard-firstpart">
          {/* <div className="row">
            {this.state.missingInfo.map((data, index) => {
              return (
                <div className="col-md-3" key={index}>
                  <div className="custom-card missed-info">
                    <img className="missed-info-img" src={data.icon} />
                    <h4>{data.title}</h4>
                    <p>{data.info}</p>
                    <BtnCustom name={data.buttonName} path={data.link} />
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="row stat-wrapper">
            {this.state.dashBoardData.map((data, index) => {
              return (
                <div className="col-md-3" key={index}>
                  <div className="custom-card">
                    <i class={data.icon}></i>
                    <h4>{data.number}</h4>
                    <p>{data.heading}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default LandingComp;
