import React from 'react'
import FaqList from '../Components/FaqComp/FaqList';
import MainDashBoard from './MainDashBoard';

const Faq = () => {
    return (
        <>
            <MainDashBoard>
           <FaqList/>
           </MainDashBoard>
           
        </>
    )
}

export default Faq
