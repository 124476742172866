import React from "react";

const StatementTableData = ({statements}) => {
  const tableHead = ["Statement ID", "From", "To"];
  const tableData = statements;
  const download = (e) => {
    e.preventDefault();
  }
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {tableHead.map((data, index) => {
              return (
                <th scope="col" key={index}>
                  {data}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => {
            return (
              <tr key={index}>
                <td> {data.ref_id}</td>
                <td> {data.from_date}</td>
                <td> {data.to_date}</td>
                {/* <td>
                  <i className="fas fa-download table-download" onClick={download}></i>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default StatementTableData;
