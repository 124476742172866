import React from "react";

const StatementTableData = () => {
  const tableHead = ["Job Date", "Booking ID", "Amount", "Status"];
  const tableData = [
    {
      date: "08 dec 2021",
      id: "BK895", 
      amount: "$123.20",
      Status: "Inactive",
      Status_tag: "text-danger",
    },
    {
      date: "08 dec 2021",
      id: "BK215", 
      amount: "$163.20",
      Status: "Active",
      Status_tag: "text-success",
    },
    {
      date: "08 dec 2021",
      id: "BK753", 
      amount: "$233.20",
      Status: "Inactive",
      Status_tag: "text-danger",
    },
  ];
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {tableHead.map((data, index) => {
              return (
                <th scope="col" key={index}>
                  {data}
                </th>
              );
            })}
          </tr>
        </thead> 
        <tbody>
          {tableData.map((datas, index) => {
            console.log(datas);
            return (
              <tr key={index}>
                <td> {datas.date}</td>
                <td> {datas.id}</td>
                <td> {datas.amount}</td>
                <td className={datas.Status_tag}> {datas.Status}</td>
                 
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default StatementTableData;
