import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { postRequest } from "../Helpers/RequestApi";


const ResetPassword = () => {
  const [new_password, setNewPassword] = useState();
  const [confirm_password, setConfirmPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let otpForgotData = JSON.parse(localStorage.getItem("otpForgotData"));
  if (!otpForgotData || otpForgotData == null || !otpForgotData.api_key) {
    navigate("/login")
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let params={ new_password:new_password,confirm_password:confirm_password};
    setIsLoading(true)
    postRequest("apply-new-password?api_key="+otpForgotData.api_key, params)
      .then((res) => {
        setIsLoading(false)
        localStorage.removeItem('otpForgotData')
        navigate("/login")
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  return (
    <>
     <ToastContainer/>
      <div className="page-alignment">
        <div className="form-wrapper  custom-card">
          <div className="verify-content">
            <h1>Reset Password</h1>
          </div>
          <Form onSubmit={onSubmit}>
            <Form.Control className="mb-3" type="password" placeholder={"New Password"} name="new_password" required onChange={(e) => setNewPassword(e.target.value)} />
            <Form.Control className="mb-3" type="password" placeholder={"Confirm Password"} name="confirm_password" required onChange={(e) => setConfirmPassword(e.target.value)} />
            <button className="btn btn-main btn-block" disabled={isLoading}>Reset</button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
