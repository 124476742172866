import React from "react";
import SearchInvoiceStatementCard from "../SearchInvoiceStatementCard";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Form } from "react-bootstrap";
import StatementTableData from "./StatementTableData";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import { toast, ToastContainer } from "react-toastify";
import { getRequest } from "../../Helpers/RequestApi";

class StatementCompTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isLoading: false,
      startDate: "",
      endDate: "",
      amounts: { opening: 0, debit: 0, credit: 0 },
      statements: [],
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleCallback = (start, end) => {
    this.setState({ startDate: start.format("MM/D/YYYY") });
    this.setState({ endDate: end.format("MM/D/YYYY") });
  };

  getStatements = (startDate = "", endDate = "") => {
    let uri="statements?api_key=" + this.user.api_key
    if(startDate && endDate){
      uri +="&date_from="+startDate+"&date_to="+endDate
    }
    getRequest(uri)
      .then((res) => {
        this.setState({ isLoading: false });
        this.setState({ amounts: res.data.amounts });
        this.setState({ statements: res.data.statements });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.getStatements(this.state.startDate,this.state.endDate);
  };

  componentDidMount = () => {
    this.getStatements();
  };
  render() {
    return (
      <>
        <ToastContainer />
        <div className="container top-align">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="row doc-stat-wrap ">
                <div className="col-md-4">
                  <div className="custom-card ">
                    <h3>{this.state.amounts.opening}</h3>
                    <p>Opening Balance</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="custom-card">
                    <h3>{this.state.amounts.debit}</h3>
                    <p>Debit</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="custom-card">
                    <h3>{this.state.amounts.credit}</h3>
                    <p>Credit</p>
                  </div>
                </div>
              </div>
              <div className="custom-card ">
                <StatementTableData statements={this.state.statements}/>
              </div>
            </div>
            <div className="col-md-4">
              {/* <a
                href="#!"
                className="btn-primary btn btn-block mb-3"
                onClick={this.showModal}
              >
                Generate Statement
              </a> */}
              <div className="custom-card  sidebar-filter">
                <h3>Search Statement</h3>
                <p>Choose Date</p>
                <form onSubmit={this.onSearch}>
                  {/* <DateRangePicker onCallback={this.handleCallback}>
                    <input type="text" className="form-control mb-3" required />
                  </DateRangePicker> */}
                  <button
                    className="btn btn-main btn-block mt-3"
                    disabled={this.state.isLoading}
                  >
                    Search
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <ModalHeader>
            <i className="fa fa-close close-modal" onClick={this.hideModal}></i>
          </ModalHeader>

          <Modal.Body>
            <Form>
              <Form.Label>Choose Date</Form.Label>
              <Form.Control className="mb-3" type="text" />
              <button className="btn btn-main btn-block mt-3">
                Generate Statement
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default StatementCompTable;
