import React from "react";
import InvoiceStatementTable from "./InvoiceStatementTable";
import SearchInvoiceStatementCard from "./SearchInvoiceStatementCard";
const InvoiceStatementComp = ({
  heading,
  payment1,
  payment2,
  amt1,
  amt2,
  statement,
}) => {
  return (
    <>
      <div className="container top-align">
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="statement-invoice-bg">
              <h2>{heading}</h2>
              <div className="dr-cr-wrapper">
                <div>
                  <p>{payment1}</p>
                  <p>{amt1}</p>
                </div>
                <div>
                  <p>{payment2}</p>
                  <p>{amt2}</p>
                </div>
              </div>
              <h5 className="mt-2">{statement}</h5>
            </div>
          </div>
          <div className="col-md-4">
            <SearchInvoiceStatementCard search="Invoice"/>
          </div>
        </div>
        <InvoiceStatementTable />
      </div>
    </>
  );
};

export default InvoiceStatementComp;
