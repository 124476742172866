import React from 'react'
import LoginForm from '../Components/LoginComp/LoginForm'

const LoginPage = () => {
    return (
        <>
            <LoginForm/>
        </>
    )
}

export default LoginPage
