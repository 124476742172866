import React from 'react'
import BankDetails from '../Components/BankInfo/BankInfoDetails';
import MainDashBoard from './MainDashBoard';

const ViewProfilePage = () => {
    return (
        <>
           <MainDashBoard>
           <BankDetails/>
           </MainDashBoard>
        </>
    )
}

export default ViewProfilePage
