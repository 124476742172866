import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { postRequest } from '../../Helpers/RequestApi'
const termsCondition=[
    { desc:"Condimentum ut penatibus dapibus dolorum mus! Scelerisque, modi, netus incididunt, provident lacinia nisi cubilia ullamco nemo! Hendrerit aut quas eos voluptate numquam dictum deserunt. Inceptos voluptate tempus molestias rhoncus lorem, litora mus iusto viverra malesuada dolorum itaque nobis a nihil! Aliqua aut accusantium itaque. Debitis. Rerum ornare placeat! Distinctio necessitatibus."},
    { desc:"Condimentum ut penatibus dapibus dolorum mus! Scelerisque, modi, netus incididunt, provident lacinia nisi cubilia ullamco nemo! Hendrerit aut quas eos voluptate numquam dictum deserunt. Inceptos voluptate tempus molestias rhoncus lorem, litora mus iusto viverra malesuada dolorum itaque nobis a nihil! Aliqua aut accusantium itaque. Debitis. Rerum ornare placeat! Distinctio necessitatibus."},
    { desc:"Condimentum ut penatibus dapibus dolorum mus! Scelerisque, modi, netus incididunt, provident lacinia nisi cubilia ullamco nemo! Hendrerit aut quas eos voluptate numquam dictum deserunt. Inceptos voluptate tempus molestias rhoncus lorem, litora mus iusto viverra malesuada dolorum itaque nobis a nihil! Aliqua aut accusantium itaque. Debitis. Rerum ornare placeat! Distinctio necessitatibus."},
    { desc:"Condimentum ut penatibus dapibus dolorum mus! Scelerisque, modi, netus incididunt, provident lacinia nisi cubilia ullamco nemo! Hendrerit aut quas eos voluptate numquam dictum deserunt. Inceptos voluptate tempus molestias rhoncus lorem, litora mus iusto viverra malesuada dolorum itaque nobis a nihil! Aliqua aut accusantium itaque. Debitis. Rerum ornare placeat! Distinctio necessitatibus."},
]

const TermServices = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem("user"));
        setIsLoading(true)
        postRequest("accept-terms?api_key=" + user.api_key)
          .then((res) => {
            setIsLoading(false)
            navigate ("/dashboard")
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error.response.data.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          })
    }
    return (
        <>
            <ToastContainer/>
            <div className='container section-gutter'>
            <div className='row'>
                <div className='col-md-12 termsservice-desc'>
                    <h1>Terms of Service</h1>
                    <h4>Before you proceed you must read and agree our Terms of Service</h4>
                    {
                        termsCondition.map((data,index)=>{
                            return(
                                <p key={index}>{data.desc}</p>
                            )
                        })
                    }
                    <Link to="" className='term-service-btn' disabled={isLoading} onClick={onSubmit}>I Agree</Link>
                </div>
            </div> 
            </div>
        </>
    )
}

export default TermServices
