import React from 'react'
import SupportList from '../Components/SupportComp/SupportList';
import MainDashBoard from './MainDashBoard';


const Support = () => {
    return (
        <>
            <MainDashBoard>
           <SupportList/>
           </MainDashBoard>

        </>
    )
}

export default Support
