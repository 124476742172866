import React from "react";
import SearchInvoiceStatementCard from "../SearchInvoiceStatementCard";
import { Link } from "react-router-dom";
import EarningTableData from "./EarningTableData";
const EarningCompTable = ({ TotalEarnings, From, To }) => {
  return (
    <>
      <div className="container top-align">
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="row doc-stat-wrap ">
              <div className="col-md-4">
                <div className="custom-card ">
                  <h3>{TotalEarnings}</h3>
                  <p>Total Earnings</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="custom-card">
                  <h3>{From}</h3>
                  <p>From</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="custom-card">
                  <h3>{To}</h3>
                  <p>To</p>
                </div>
              </div>
            </div>
            <div className="custom-card ">
              <EarningTableData />
            </div>
          </div>
          <div className="col-md-4">
            <SearchInvoiceStatementCard search="Earnings" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EarningCompTable;
