import React from 'react'
import NotificationTabs from '../Components/NotificationComp/NotificationTabs';
import MainDashBoard from './MainDashBoard';

const Notification = () => {
    return (
        <>
            <MainDashBoard>
           <NotificationTabs/>
           </MainDashBoard>
        </>
    )
}

export default Notification
