import React from "react";
import ScheduleNavTab from "../Components/ScheduleBookingComp/ScheduleNavTab";
import MainDashBoard from "./MainDashBoard";

const ScheduleBookingPage = () => {
  return (
    <>
      <MainDashBoard>
        <ScheduleNavTab />
      </MainDashBoard>
    </>
  );
};

export default ScheduleBookingPage;
