import React from "react";
import { Form } from "react-bootstrap";
import NavAndTabs from "../NavAndTabs";

const ScheduleNavTab = () => {
  return (
    <>
      <div className="container top-align">
        <div className="row">
          <div className="col-lg-8">
            <NavAndTabs />
          </div>
          {/* <div className="col-lg-4">
            <div className="custom-card sidebar-filter">
              <h3>Search Booking</h3>
              <Form>
                <Form.Label>Choose Date</Form.Label>
                <Form.Control className="mb-3" type="text" />
                <button className="btn btn-main btn-block mt-3">Search</button>
              </Form>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ScheduleNavTab;
