import React, { useState } from "react";
import Logo from "../Logo";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import BtnCustom from "../BtnCustom"; 
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { postRequest } from "../../Helpers/RequestApi";


const LoginForm = () => {
  const [country_code, setCC] = useState();
  const [country_number, setCN] = useState();
  const [phone, setPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (e) => {
    let params={ country_code: country_code,country_number: country_number,phone: phone };
    if(!params.phone){
      toast.error("Please enter phone number", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return
    }
    setIsLoading(true);
    postRequest("send-verification-code", params)
      .then((res) => {
        localStorage.setItem('otpData', JSON.stringify(res.data));
        setIsLoading(false);
        navigate("/verifycode");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
     <ToastContainer />
      <div className="page-alignment">
        <div className="form-wrapper  custom-card">
          <Logo /> 
          <p className="login-para">
            Proceed with your Phone number. We'll send your verfiy code.
          </p>
          <IntlTelInput
            containerClassName="intl-tel-input"
            inputClassName="form-control"
            onPhoneNumberChange={(b, n, c) => {
              setCC(c.iso2)
              setCN(c.dialCode)
              setPhone(n)
            }}
          />
          <button className="btn btn-main btn-block mt-2" onClick={onSubmit} disabled={isLoading}>Get a Code</button>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
