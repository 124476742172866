import axios from "axios";

// const apiUrl = 'http://localhost/gologanairport/api/driver/';
const apiUrl = 'https://dispatch.gologanairport.com/api/driver/';

export const getRequest = (url) => {
  url = apiUrl + url;
  return axios.get(url).then((res) => res.data);
};

export const postRequest = (url, params = {},isMultipart=false) => {
  let headers
  if(isMultipart){
    headers={
      'Content-Type':'multipart/form-data'
      // Authorization: "Basic ZGlzcGF0Y2hfYXBpOnNLaTFheD5IOTJYeFA2Zw",
    }
  }else{
    headers = {
      // Authorization: "Basic ZGlzcGF0Y2hfYXBpOnNLaTFheD5IOTJYeFA2Zw",
    }
  }
  return axios.post(apiUrl + url, params, { headers: headers }).then((res) => res.data);
};


