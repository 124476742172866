import React from "react";
import "react-tabs/style/react-tabs.css";
import TableChart from "./TableChart";


const InvoiceStatementTable = () => {
 

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-8">
          <TableChart />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default InvoiceStatementTable;
