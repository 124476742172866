import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BookingComp from "../BookingComp";
import { Link } from "react-router-dom";

const notificationPromotion=[
    {icon:"fas fa-check-circle",heading:"Booking Accepted",desc:'Your booking has been accepted by driver Andrew'},
    {icon:"fas fa-car",heading:"Driver On Destination",desc:'Your driver has been reached to your destination'},
    {icon:"far fa-credit-card",heading:"Payment Done",desc:'Your payment has been done successfully'},
]

const NotificationTabs = () => {
  return (
    <>
      <div className="container top-align">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 notification-tab">
            <Tabs>
              <TabList>
                <Tab>Promotional</Tab>
                <Tab>Booking</Tab>
              </TabList>

              <TabPanel>
                  <ul className="mt-4">
                      {
                        notificationPromotion.map((data,index)=>{
                            return(

                      <Link to="" key={index} >
                          <li className="promotional-link mb-3">
                              <div className="d-flex align-items-center">
                                  <i className={data.icon}></i>
                                    <div className="mt-3">
                                        <h5>{data.heading}</h5>
                                        <p>{data.desc}</p>
                                    </div>
                              </div>
                          </li>
                      </Link>
                            )
                        })  
                      }
                  </ul>
              </TabPanel>
              <TabPanel>
                <BookingComp />
                <BookingComp />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationTabs;
