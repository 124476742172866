import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Form } from "react-bootstrap";

const ScheduleNavTab = () => {
  return (
    <>
      <div className="container top-align">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="custom-card">
              <Tabs>
                <TabList>
                  <Tab>Complaint</Tab>
                  <Tab>Account</Tab>
                  <Tab>Lost & Found</Tab>
                  <Tab>Cancellation</Tab>
                </TabList>

                <TabPanel>
                  <Form>
                    <Form.Label>Job Id</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      placeholder="Eg. BK-1289"
                    />
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      className="mb-3"
                      as="textarea"
                      rows={3}
                      placeholder="Eg. Rude Passenger"
                    />
                    <button className="btn btn-main btn-block mt-3">
                      Submit
                    </button>
                  </Form>
                </TabPanel>
                <TabPanel>
                  <Form>
                    <Form.Label>Job Id</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      placeholder="Eg. BK-1289"
                    />
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      className="mb-3"
                      as="textarea"
                      rows={3}
                      placeholder="Eg. Payment is Pending"
                    />
                    <button className="btn btn-main btn-block mt-3">
                      Submit
                    </button>
                  </Form>
                </TabPanel>
                <TabPanel>
                  <Form>
                    <Form.Label>Job Id</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      placeholder="Eg. BK-1289"
                    />
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      className="mb-3"
                      as="textarea"
                      rows={3}
                      placeholder="Eg. Found Bag "
                    />
                    <button className="btn btn-main btn-block mt-3">
                      Submit
                    </button>
                  </Form>
                </TabPanel>
                <TabPanel>
                  <Form>
                    <Form.Label>Job Id</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      placeholder="Eg. BK-1289"
                    />
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      className="mb-3"
                      as="textarea"
                      rows={3}
                      placeholder="Eg. Passenger No Show up"
                    />
                    <button className="btn btn-main btn-block mt-3">
                      Submit
                    </button>
                  </Form>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleNavTab;
