import React from "react";
import { Link } from "react-router-dom"; 
const sideBarMenu = [
  {
    icon: "fas fa-calendar-week",
    name: "Jobs",
    path:"/jobs"
  },
  // {
  //   icon: "far fa-life-ring",
  //   name: "Driver Document",
  //   path:"/driverdocument"
  // },
  // {
  //   icon: "far fa-life-ring",
  //   name: "Fleet & Document",
  //   path:"/fleetanddocument"
  // },
  // {
  //   icon: "fas fa-file",
  //   name: "Invoice",
  //   path:"/invoice"
  // },
  {
    icon: "fas fa-file",
    name: "Statement",
    path:"/statements"
  },
  // {
  //   icon: "fas fa-file",
  //   name: "Payouts",
  //   path:"/statements"
  // },
  {
    icon: "fas fa-question-circle",
    name: "Earnings",
    path:"/earnings"
  },
  // {
  //   icon: "far fa-life-ring",
  //   name: "Privacy",
  //   path:"/supports"
  // },
  
  // {
  //   icon: "fas fa-cog",
  //   name: "Legal",
  //   path:"/setting"
  // },
];
const sideBarExtLink = [
     {
    icon: "far fa-life-ring",
    name: "Privacy",
    link:"#!"
  },
  
  {
    icon: "fas fa-cog",
    name: "Legal",
    link:"#!"
  },
];

const SideBar = ({sidebarshow}) => {
 
 return (
    <>
      <div className={sidebarshow?"open-menu sidebar":"sidebar"}>
     
          <ul className="menu-list">
            {sideBarMenu.map((menu, index) => {
              return (
               
                <li >
                    <Link to={`${menu.path}`} key={index}>
                    {/* <i className={menu.icon}></i> */}
                    <span>{menu.name}</span>
                  </Link>
                  </li>
                  
              );
            })}
            {sideBarExtLink.map((menu, index) => {
              return (
               
                <li >
                    <a href={menu.link} key={index}>
                    <span>{menu.name}</span>
                  </a>
                  </li>
                  
              );
            })}
          </ul>
       
      </div>
    </>
  );
};

export default SideBar;
