import React from 'react'
import InvoiceStatementComp from '../Components/InvoiceStatementComp'
import MainDashBoard from './MainDashBoard';

const Invoice = () => {
    
    return (
        <>
           
           <MainDashBoard>
         {<InvoiceStatementComp heading="Total Invoice &nbsp; $453.0" payment1="Total Paid" amt1="$48.12" payment2="Unpaid" amt2="48.33" statement="Invoice as of 26 DEC 2021"/>}  
         </MainDashBoard>
           
        </>
    )
}

export default Invoice
