import React from "react";

const TableChart = () => {
  const tableHead = ["Date", "Invoice ID", "Amount", "Status"];
  const tableData = [
    {
      date: "08 dec 2021",
      id: "inv4155",
      amt: "50.55",
      status: "Paid",

    },
    {
      date: "08 dec 2021",
      id: "inv4155",
      amt: "50.55",
      status: "Unpaid",
      
    },
    {
      date: "08 dec 2021",
      id: "inv4155",
      amt: "50.55",
      status: "Paid",
      
    },
  ];
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {tableHead.map((data, index) => {
              return <th scope="col" key={index}>{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((datas, index) => {
            console.log(datas);
            return (
              <tr key={index}>
                <td> {datas.date}</td>
                <td> {datas.id}</td>
                <td> {datas.amt}</td>
                
                {
                  datas.status === "Paid" ?  <td style={{color: "green"}}>{datas.status}</td> : <td style={{color: "red"}}>{datas.status}</td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TableChart;
