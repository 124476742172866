import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Logo from "../Logo";
import { postRequest } from "../../Helpers/RequestApi";
import { ToastContainer, toast } from "react-toastify";

const DashBoardHeader = ({ onIcon, click }) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const loggedUserName = user.name || "Passenger";

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  const logout = () => {
    postRequest("logout?api_key=" + user.api_key, {})
      .then((res) => {
        localStorage.removeItem("user");
        navigate("/login");
      })
      .catch((error) => {
        localStorage.removeItem("user");
        toast.error(error.response.data.msg || "Server error", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/login");
      });
  };

  return (
    <>
      <ToastContainer />
      <header className="web-header">
        <div className="for-position">
          <div className="row">
            {/* <div className="col-md-4 d-flex  align-items-center">
              <i
                onClick={onIcon}
                className={click ? "fas fa-times" : "fas fa-bars"}
              ></i>
             
            </div> */}
            <div className="col-md-12 text-center">
              <div className="d-flex  justify-content-between gap-15">
                <div className="menu-wrapper">
                  <Logo />
                  <Link to="/jobs">Jobs</Link>
                  <Link to="/statements">Statement</Link>
                  <Link to="">Privacy</Link>
                  <Link to="">Legal</Link>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant=" " id="dropdown-basic">
                    <i class="far fa-user"></i> {loggedUserName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/viewprofilepage");
                      }}
                    >
                      Profile
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={()=>{navigate('/bankdetails')}}>Bank Account</Dropdown.Item>
                    <Dropdown.Item href="">Support</Dropdown.Item> */}
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header class="mob-header">
        <Link to="/dashboard">
          <Logo />
        </Link>

        <a
          href="javascript:void(0"
          onClick={handleToggle}
          className="responsive-menu-btn"
        >
          <i className={isActive ? "fa fa-bars" : "fa fa-times"}></i>
        </a>
      </header>
      <ul className={isActive ? "nav mob-nav hide" : "nav mob-nav show"}>
        <li>
          <a href="/dashboard">Dashboard</a>
        </li>

        <li>
          <a href="/viewprofilepage">Profile</a>
        </li>
        <li>
          <Link to="/jobs">Jobs</Link>{" "}
        </li>
        <li>
          <Link to="/statements">Statement</Link>{" "}
        </li>
        <li>
          <Link to="">Privacy</Link>{" "}
        </li>
        <li>
          <Link to="">Legal</Link>
        </li>

        <li>
          <a href="javascript:void()" onClick={logout}>
            Logout
          </a>
        </li>
      </ul>
    </>
  );
};

export default DashBoardHeader;
